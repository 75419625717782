/* @import 'node_modules/react-modal-video/scss/modal-video.scss'; */

* {
  box-sizing: border-box;
  margin: 0;
  /* padding: 0; */
  font-family: 'Proxima Nova Rg';
  /* max-width: 1980px; */
  
  
}
html, body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  /* display: flex;
  flex-direction: row; */
}

/* @import "compass/css3"; */

.containernews {
  position: relative;
  border: 0.5px solid #999696;
  height: 50px;
  border-radius: 100px;
  background-color: #ffffff;
  overflow: hidden;
  font-size: 12px;
  /* /* margin-bottom: 10px; */
} 

.text_input {
  height: 44px;
  width: 60%;
  padding: 0;
  /* border-radius: 50%; */
  line-height: 20px;
  font-size: 14px;
  padding: 0;
  background: transparent;
  color: #000000;
  margin-left: 20px;
  border: none;
}
.text_input:focus {
  outline: none;
}

.btn {
  position: absolute; 
  height: 50px;
  line-height: 50px;
  right: 0;
  top: 0;
  margin: 0;
  border-radius: 100px;
  padding: 0;
  background: #3cb878;
  color: white;
  border: none;
  width: 30%;
  font-weight: bold;
}

.btn:hover {
  color: black;
  cursor: pointer;
}

/* OFFERS */
.flip-card {
  background-color: transparent;
  width: 300px;
  height: 300px;
  perspective: 1000px;
  margin: 20px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  background-color: #edf9f9;
  color: #edf9f9;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.slick-prev:before,
.slick-next:before {
  color: red!important;
  font-size: 30px;
}


.dropbtn {
  /* background-color: #04AA6D; */
  color: black;
  padding: 14px;
  background: transparent;
  font-size: 16px;
  font-weight: bold;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background: #fefefe;
  min-width: 160px;
  /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
  border-radius: 15px;
  z-index: 0;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 14px;
  font-weight: bolder;
}

.dropdown-content a:hover {background-color: #ddd;} 

.dropdown:hover .dropdown-content {display: block;}

 /* .dropdown:hover .dropbtn {background-color: #3e8e41;}  */


 .cb-centermode {
  margin-bottom: 20px;
  display: block;
}
.cb-centermode input {
  margin-right: 7px;
}

/* card style start */
.App {
  width: 100%;
  margin: 30px;
}

.img-card {
  border-radius: 4px;
  background: #f5f5f5;
  color: #666;
  overflow: hidden;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}

.img-card:hover {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  /* padding: 100px; */
  height: 300px;
  transform: scale(1.06);
    transition: all 0.3s ease-out;
    color: #1c2237;
}

.img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.card-body {
  padding: 15px;
}

.card-title {
  font-size: 20px;
  /* font-weight: 600; */
}

.card-text {
  margin-top: 10px;
}

/* card style end */


/* carousel slider style start */

.slick-slide > div {
  margin: 0 10px;
}

.slick-list {
  margin: 0 -10px;
}

.slick-slide *:focus{
  outline: none;
}
/* carousel slider style end */


/* drop up  */

.dropup {
  position: relative;
  display: inline-block;
}

.dropup-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  bottom: 50px;
  z-index: 1;
  border-radius: 8px;
  transition: all .3s ease-out;
}

.dropup-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropup-content a:hover {background-color: #ccc}

.dropup:hover .dropup-content {
  display: block;
}

/* .dropup:hover .dropbtn {
  background-color: #2980B9;
} */