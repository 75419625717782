
.card {
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 24rem;
  position: relative;
  border: 1px solid #ccc;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-header span {
  color: #2d3748;
}

.card-image {
  width: 100%;
  height: 14rem;
  max-width: 100%;
  object-fit: cover;
}

.card button {
  display: inline-block;
  background-color: #14c8eb;
  color: #fff;
  border: none;
  padding: 1.5em 1.6em;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
  width: 100%;
  position: absolute;
  bottom: 0;
  transition: background-color 0.5s ease-out;
}

.card button:focus {
  outline: none;
}

.card button:hover {
  background-color: #0ea8c7;
}

.text-info {
  padding: 1rem 1.5rem;
  margin-bottom: 2.5rem;
}

.controls {
  display: flex;
  justify-content: end;
  margin-bottom: 1.5rem;
}

.controls button {
  width: 40px;
  height: 40px;
  border: 0;
  background-color: #14c8eb;
  color: white;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.5s ease-out;
}

.controls button:hover {
  background-color: #0ea8c7;
}

.controls button:nth-child(1) {
  margin-right: 2rem;
}